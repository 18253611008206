<template>
  <div class="formAuther-container">
    <div class="form-item" v-for="(v, i) in formValidate.autherList" :key="i">
      <Input
        placeholder="请输入发明人"
        v-model.trim="v.authorName"
        @input="otherInput(v)"
        @on-focus="handleFocus1(v)"
        @on-blur="blurAuther1(v)"
      />
      <ul class="select-list" v-if="v.showSelect1">
        <li
          class="select-item"
          v-for="item in firstAutherList"
          :key="item.id"
          @click="echoAuther1(v, item)"
        >
          <span>{{ item.realname }}</span>
          <span style="width: 500px">{{ item.orgName }}</span>
          <span>{{ item.mobile }}</span>
        </li>
        <p class="icon-pager">
          <a class="pager-left" @mousedown="handlePager1(v, 'turnLeft', $event)"
            >&lt;&lt;</a
          >
          <a
            class="pager-right"
            @mousedown="handlePager1(v, 'turnRight', $event)"
            >&gt;&gt;</a
          >
        </p>
        <div class="none" @click="editAuther1(v)">都不是，直接添加</div>
      </ul>
      <Button
        class="plus"
        @click="subAuther(i)"
        v-if="formValidate.autherList.length - 1 !== i"
        v-throttleClick
      >
        -
      </Button>
      <Button
        class="plus"
        @click="addAuther(v)"
        v-if="formValidate.autherList.length - 1 === i"
        v-throttleClick
      >
        +
      </Button>
      <span class="xing">*</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formValidate: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      firstAutherList: [], //作者列表
      // 分页
      curPage: 1,
      total: 0,
      limit: 0,
    };
  },
  computed: {
    allPage() {
      return Math.ceil(this.total / this.limit);
    },
  },
  methods: {
    handlePager1(v, type, e) {
      e.preventDefault();
      if (type === "turnLeft") {
        this.curPage--;
      } else {
        this.curPage++;
      }
      if (this.curPage < 1) {
        this.curPage = 1;
      }
      if (this.curPage > this.allPage) {
        this.curPage = this.allPage;
      }
      this.findAuthorInfoByNameAndCompany({
        name: v.authorName,
        pageNo: this.curPage,
        pageSize: 5,
      });
    },
    editAuther1(v) {
      v.unitDisabled1 = false;
      v.showSelect1 = false;
      v.authorUnitId = null;
      v.authorId = null;
      this.formValidate.articleAuthorList.forEach((item) => {
        item.authorUnitId = null;
        item.authorId = null;
      });
    },
    echoAuther1(v, item) {
      v.authorName = item.realname;
      v.authorId = item.id;
      v.showSelect1 = false;
      v.unitDisabled1 = true;
      this.$emit("autherInfo1", this.formValidate.autherList);
    },
    blurAuther1(v) {
      setTimeout(() => {
        v.showSelect1 = false;
      }, 100);
      this.$emit("autherInfo1", this.formValidate.autherList);
    },
    handleFocus1(v) {
      if (v.authorName === "") return;
      this.findAuthorInfoByNameAndCompany({
        name: v.authorName,
        pageNo: 1,
        pageSize: 5,
      });
      this.formValidate.autherList.forEach((item) => {
        return (item.showSelect1 = false);
      });
      v.showSelect1 = true;
    },
    otherInput(v) {
      if (v.authorName !== "") {
        v.showSelect1 = true;
        this.findAuthorInfoByNameAndCompany({
          name: v.authorName,
          pageNo: 1,
          pageSize: 5,
        }); // 查询文章发布作者
      } else {
        v.showSelect1 = false;
      }
    },
    // 查询文章发布作者
    async findAuthorInfoByNameAndCompany(data) {
      const resp = await this.$apis.formServe.findAuthorInfoByNameAndCompany(
        data
      );
      this.firstAutherList = resp.data.list;
      this.total = +resp.data.total;
      this.limit = +resp.data.pageSize;
    },
    // 添加其他作者
    addAuther(v) {
      if (v.authorName == "") {
        this.$Message.error("发明人不能为空");
        return;
      }
      this.formValidate.autherList.push({
        authorName: "",
        showSelect1: false,
        unitDisabled1: true,
        authorId: "",
      });
    },
    subAuther(index) {
      this.formValidate.autherList.splice(index,1)
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-input[disabled],
fieldset[disabled] .ivu-input {
  opacity: 0.7;
}
.ivu-input-wrapper ::v-deep input {
  border: none;
  background-color: #fff;
  height: 38px;
}
.ivu-input-wrapper ::v-deep input::-webkit-input-placeholder,
.ivu-input-wrapper ::v-deep textarea::-webkit-input-placeholder {
  color: #999;
}
.form-item {
  width: 100%;
  min-height: 85px;
  padding: 25px 0;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  position: relative;
  border-bottom: 1px solid #dcdcdc;
  .plus {
    margin: 0 5px;
    padding: 0;
    width: 16px;
    height: 16px;
    line-height: 14px;
    background: #FFFFFF;
    border: 1px dotted #00A4FF;
    border-radius: 3px;
    font-size: 10px;
    color: #00A4FF;
  }
  .icon-pager {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      display: inline-block;
      width: 50px;
      text-align: center;
      user-select: none;
      cursor: pointer;
      color: #999;
      &:active {
        color: #dcdcdc;
      }
    }
  }
  .select-item,
  .none {
    display: flex;
    justify-content: space-around;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    &:hover {
      background-color: rgb(212, 209, 209);
    }
    span {
      display: inline-block;
      width: 130px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .select-list {
    position: absolute;
    left: 0;
    top: 88px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    z-index: 1;
  }
  .title {
    width: 900px;
    height: 38px;
    line-height: 38px;
    position: relative;
    ::v-deep input {
      font-size: 36px;
    }
    .right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .xing {
    width: 30px;
    color: red;
    text-align: center;
  }
  .left,
  .right {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .left {
    border-right: 1px solid #dcdcdc;
    width: 355px;
  }
  .right {
    margin-left: 10px;
    width: 642px;
  }
  .year,
  .center,
  .stage {
    display: flex;
    align-items: center;
  }
  .year {
    width: 299px;
    border-right: 1px solid #dcdcdc;
  }
  .center {
    width: 323px;
    border-right: 1px solid #dcdcdc;
  }
  .stage {
    width: 374px;
  }
  .checkbox {
    width: 970px;
    display: flex;
    padding-left: 7px;
    label {
      margin-right: 20px;
    }
  }
  .upload {
    display: flex;
    padding-left: 7px;
    align-items: center;
    label {
      margin-right: 20px;
    }
  }
}
</style>
