<template>
  <div class="form-content-container">
    <Form class="form-container">
      <div class="form-item">
        <div class="title">
          <Input
            placeholder="请输入专利名称(5-30字)"
            v-model.trim="formValidate.title"
            maxlength="30"
          />
        </div>
        <div class="number">{{ formValidate.title.length }}/30</div>
      </div>
      <div class="form-item">
        <Select v-model="formValidate.patentType" placeholder="请选择专利类型">
          <Option value="外观设计">外观设计</Option>
          <Option value="实用新型">实用新型</Option>
          <Option value="发明公开">发明公开</Option>
          <Option value="发明授权">发明授权</Option>
        </Select>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入申请（专利）号"
          v-model.trim="formValidate.patentNumber"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <DatePicker
          type="date"
          placeholder="请选择申请日期"
          style="width: 200px"
          @on-change="changeTime"
          :value="formValidate.applyTime"
          :editable="false"
        ></DatePicker>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入公开/公告号"
          v-model.trim="formValidate.publicNumber"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <DatePicker
          type="date"
          placeholder="请选择公开/公告日"
          style="width: 200px"
          @on-change="changeTime1"
          :value="formValidate.publishTime"
          :editable="false"
        ></DatePicker>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入申请/专利权人"
          v-model.trim="formValidate.applyUser"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入主申请人地址"
          v-model.trim="formValidate.patentAddress"
        />
        <span class="xing">*</span>
      </div>
      <!-- 发明人 -->
      <Invention
        @autherInfo1="handleAuther1"
        :formValidate="formValidate"
      ></Invention>
      <div class="form-item">
        <Input
          placeholder="请输入分类号"
          v-model.trim="formValidate.classifyNumber"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入主分类号"
          v-model.trim="formValidate.masterClassifyNumber"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入页数"
          v-model.trim="formValidate.pageNumber"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入代理机构"
          v-model.trim="formValidate.proxyMechanism"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入代理人"
          v-model.trim="formValidate.proxyUser"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          type="textarea"
          placeholder="请输入主权项"
          v-model.trim="formValidate.principalRight"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入优先权"
          v-model.trim="formValidate.priority"
        />
      </div>
      <div class="form-item">
        <Input
          placeholder="请输入国别省市代码"
          v-model.trim="formValidate.provinceNumber"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          type="textarea"
          placeholder="请输入摘要"
          v-model.trim="formValidate.artSummary"
        />
        <span class="xing">*</span>
      </div>
      <!-- 法律 -->
      <div class="form-item" v-for="(v, i) in formValidate.lagaList" :key="i">
        <div class="year">
          <Input
            placeholder="请输入法律状态公告日"
            v-model.trim="v.lagalPublishTime"
          />
          <span class="xing">*</span>
        </div>
        <div class="center">
          <Input placeholder="请输入法律状态" v-model.trim="v.lagalStatus" />
          <span class="xing">*</span>
        </div>
        <div class="stage">
          <Input
            placeholder="请输入法律状态信息"
            v-model.trim="v.lagalMessage"
          />
        </div>
        <div
          class="plus"
          @click="subLaga(i)"
          v-if="formValidate.lagaList.length - 1 !== i"
        >
          -
        </div>
        <div
          class="plus"
          @click="addLaga"
          v-if="formValidate.lagaList.length - 1 === i"
        >
          +
        </div>
      </div>
      <div class="form-item" @click="handleFileDel">
        <div class="upload">
          <label for="">附件</label>
          <Upload
            type="drag"
            :action="actionUrl"
            :format="['pdf']"
            :headers="{ token: token }"
            :on-success="handleSuccess"
            accept=".pdf"
            :show-upload-list="false"
          >
            <Button>上传</Button>
            <p style="margin-left: 7px" v-if="!formValidate.accessoryName">
              请上传pdf格式文件
            </p>
            <p style="margin-left: 7px" v-else>
              {{ formValidate.accessoryName }}
            </p>
          </Upload>
        </div>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          v-model.trim="formValidate.externalUrl"
          placeholder="请输入外部链接便于平台审核"
        />
      </div>
      <div class="form-item">
        <div class="checkbox">
          <label for="">发布栏目</label>
          <CheckboxGroup v-model="columnNum">
            <Checkbox disabled :label="columnNum[0]"></Checkbox>
          </CheckboxGroup>
        </div>
      </div>
    </Form>
    <div class="submit-container">
      <div class="btns">
        <Button
          :disabled="disableFlag"
          @click="handlePreview(1, '/patentpreview')"
          >预览</Button
        >
        <Button @click="handleSave(formValidate)" v-throttleClick="2000"
          >保存</Button
        >
        <Button @click="handleSubmit(formValidate)" v-throttleClick="2000"
          >发布</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Invention from "../components/Invention.vue";
import preview from "@/mixins/preview.js";
export default {
  components: {
    Invention,
  },
  mixins: [preview],
  created() {
    // 认证
    if (this.userInfo.user.customerType === 1) {
      this.isFlag = true;
    }
    if (this.$route.query.id) {
      this.getEcho(this.formValidate);
      this.disableFlag = false;
    }
  },
  data() {
    return {
      tempAutherList: [],
      isFlag: false,
      actionUrl: `${this.$global.url1}/zw-public/web/common/upload`, // 接口路径
      formValidate: {
        title: "",
        patentType: "",
        patentNumber: "",
        applyTime: "",
        publicNumber: "",
        publishTime: "",
        applyUser: "",
        patentAddress: "",
        classifyNumber: "",
        masterClassifyNumber: "",
        pageNumber: "",
        proxyMechanism: "",
        proxyUser: "",
        principalRight: "",
        priority: "",
        provinceNumber: "",
        artSummary: "",
        accessoryName: "",
        filePath: "",
        externalUrl: "",
        synchronizedStatus: "",
        articleAuthorList: [],
        lagaList: [
          {
            lagalPublishTime: "",
            lagalStatus: "",
            lagalMessage: "",
          },
        ],
        autherList: [
          {
            authorName: "",
            showSelect1: false,
            unitDisabled1: true,
            authorId: "",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["token", "userInfo"]),
    columnNum() {
      let arr = [];
      if (sessionStorage.getItem("isSon") === "false") {
        if (this.userInfo.user.customerType === 4) {
          arr = ["百科号"];
        } else if (this.userInfo.user.customerType === 5) {
          arr = ["机构号"];
        } else if (
          this.userInfo.user.customerType === 1 ||
          this.userInfo.user.customerType === 2
        ) {
          arr = ["百家号"];
        }
      } else {
        arr = ["学者空间"];
      }
      return arr;
    },
  },
  methods: {
    verification(formValidate) {
      if (this.formValidate.title.length < 5) {
        this.$Message.error("标题不能少于五个字");
        return false;
      }
      if (formValidate.proxyUser.length > 10) {
        this.$Message.error("代理人已达上限");
        return false;
      }
      if (formValidate.proxyMechanism.length > 50) {
        this.$Message.error("代理机构已达上限");
        return false;
      }
      if (formValidate.principalRight.length > 600) {
        this.$Message.error("主权项已达上限");
        return false;
      }
      for (let i = 0; i < formValidate.articleAuthorList.length; i++) {
        const element = formValidate.articleAuthorList[i];
        if (!this.$route.query.id && element.authorName == "") {
          this.$Message.error("发明人不能为空");
          return false;
        }
      }
      for (let i = 0; i < formValidate.lagaList.length; i++) {
        let item = formValidate.lagaList[i];
        if (item.lagalPublishTime === "") {
          this.$Message.error("法律状态公告日不能为空");
          return false;
        }
        if (item.lagalStatus === "") {
          this.$Message.error("法律状态不能为空");
          return false;
        }
        if (!/^(\d{4})-(\d{2})-(\d{2})$/.test(item.lagalPublishTime)) {
          this.$Message.error("法律状态公告日必须是日期格式YYYY-MM-DD");
          return false;
        }
      }
      let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~])+$/;
      if (!reg.test(formValidate.externalUrl) && formValidate.externalUrl !== '') {
        this.$Message.error("外部链接格式不正确");
        return false;
      }
      if (!formValidate.provinceNumber || formValidate.provinceNumber === "") {
          this.$Message.error("国别省市代码不能为空");
          return false;
      }
      if (formValidate.artSummary.length > 2500) {
        this.$Message.error("内容简介超出限制");
        return false;
      }
      return true;
    },
    // 保存
    async handleSave(formValidate) {
      if (this.isEmpty(formValidate)) {
        return false;
      }
      // 认证
      //   if (this.userInfo.user.customerType === 1) {
      //     this.isFlag = true;
      //     return;
      //   }
      if (!this.verification(formValidate)) {
        return false;
      }
      const {data} = await this.$apis.formServe.webSave({
        auditStatus: 1,
        articleType: 1,
        projectId:
          JSON.parse(sessionStorage.getItem("Table")) &&
          JSON.parse(sessionStorage.getItem("Table")).PlatformEnum[2].id,
        articleId: this.$route.query.id || this.previewData.articleId,
        patentName: formValidate.title,
        patentType: formValidate.patentType,
        patentNumber: formValidate.patentNumber,
        applyTime: formValidate.applyTime,
        publicNumber: formValidate.publicNumber,
        publishTime: formValidate.publishTime,
        applyUser: formValidate.applyUser,
        patentAddress: formValidate.patentAddress,
        classifyNumber: formValidate.classifyNumber,
        masterClassifyNumber: formValidate.masterClassifyNumber,
        pageNumber: formValidate.pageNumber,
        proxyMechanism: formValidate.proxyMechanism,
        proxyUser: formValidate.proxyUser,
        principalRight: formValidate.principalRight,
        priority: formValidate.priority,
        provinceNumber: formValidate.provinceNumber,
        artSummary: formValidate.artSummary,
        lagalPublishTime: formValidate.lagalPublishTime,
        lagalStatus: formValidate.lagalStatus,
        lagalMessage: formValidate.lagalMessage,
        accessoryName: formValidate.accessoryName,
        filePath: formValidate.filePath,
        externalUrl: formValidate.externalUrl,
        synchronizedStatus: formValidate.synchronizedStatus === true ? 1 : 0,
        articleAuthorList:
          formValidate.articleAuthorList &&
          formValidate.articleAuthorList.length > 0
            ? formValidate.articleAuthorList
            : this.tempAutherList, // 发明人
        lagaList: formValidate.lagaList,
      });
      console.log(data);
      if (data.resultCode === "200") {
        this.previewData = data.data;
        this.$Message.success(data.resultDesc);
        this.disableFlag = false;
        return true;
      } else {
        this.$Message.error('保存失败,请联系管理员');
      }
    },
    async handleSubmit(formValidate) {
      if (this.isEmpty(formValidate)) {
        return false;
      }
      // 认证
      if (this.userInfo.user.customerType === 1) {
        this.isFlag = true;
        return;
      }
      if (!this.verification(formValidate)) {
        return;
      }
      const {data} = await this.$apis.formServe.webSave({
        auditStatus: 0,
        articleType: 1,
        projectId:
          JSON.parse(sessionStorage.getItem("Table")) &&
          JSON.parse(sessionStorage.getItem("Table")).PlatformEnum[2].id,
        articleId: this.$route.query.id || this.previewData.articleId,
        patentName: formValidate.title,
        patentType: formValidate.patentType,
        patentNumber: formValidate.patentNumber,
        applyTime: formValidate.applyTime,
        publicNumber: formValidate.publicNumber,
        publishTime: formValidate.publishTime,
        applyUser: formValidate.applyUser,
        patentAddress: formValidate.patentAddress,
        articleAuthorList:
          formValidate.articleAuthorList &&
          formValidate.articleAuthorList.length > 0
            ? formValidate.articleAuthorList
            : this.tempAutherList, // 其他作者
        classifyNumber: formValidate.classifyNumber,
        masterClassifyNumber: formValidate.masterClassifyNumber,
        pageNumber: formValidate.pageNumber,
        proxyMechanism: formValidate.proxyMechanism,
        proxyUser: formValidate.proxyUser,
        principalRight: formValidate.principalRight,
        priority: formValidate.priority,
        provinceNumber: formValidate.provinceNumber,
        artSummary: formValidate.artSummary,
        lagalPublishTime: formValidate.lagalPublishTime,
        lagalStatus: formValidate.lagalStatus,
        lagalMessage: formValidate.lagalMessage,
        accessoryName: formValidate.accessoryName,
        filePath: formValidate.filePath,
        externalUrl: formValidate.externalUrl,
        synchronizedStatus: formValidate.synchronizedStatus === true ? 1 : 0,
        lagaList: formValidate.lagaList,
      });
      if (data.resultCode === "200") {
        this.$Message.success(data.resultDesc);
        this.$router.push("/user");
        this.clearField(); // 清空
      } else {
        this.$Message.error('发布失败,请联系管理员')
      }
    },
    // 回显
    async getEcho(f) {
      const resp = await this.$apis.formServe.getEcho({
        articleId: this.$route.query.id,
        articleType: 1,
      });
      const r = resp.data.data;
      // 法律
      for (let i = 0; i < r.lagaList.length - 1; i++) {
        f.lagaList.push({
          lagalPublishTime: "",
          lagalStatus: "",
          lagalMessage: "",
        });
      }
      r.lagaList.forEach((vv, index) => {
        f.lagaList[index].lagalPublishTime = vv.lagalPublishTime.split(" ")[0];
        f.lagaList[index].lagalStatus = vv.lagalStatus;
        f.lagaList[index].lagalMessage = vv.lagalMessage;
      });
      for (let j = 0; j < r.inventor.split(",").length - 1; j++) {
        f.autherList.push({
          authorName: "",
          showSelect1: false,
          unitDisabled1: true,
          authorId: "",
        });
      }
      for (let j = 0; j < r.inventor.split(",").length; j++) {
        f.autherList[j].authorName = r.inventor.split(",")[j];
        f.autherList[j].authorId = r.inventorId.split(",")[j];
      }
      this.tempAutherList = f.autherList;
      f.title = r.patentName;
      f.patentType = r.patentType;
      f.patentNumber = r.patentNumber;
      f.applyTime = r.applyTime;
      f.publicNumber = r.publicNumber;
      f.publishTime = r.publishTime;
      f.applyUser = r.applyUser;
      f.patentAddress = r.patentAddress;
      f.classifyNumber = r.classifyNumber;
      f.masterClassifyNumber = r.masterClassifyNumber;
      f.pageNumber = r.pageNumber;
      f.proxyMechanism = r.proxyMechanism;
      f.proxyUser = r.proxyUser;
      f.principalRight = r.principalRight;
      f.priority = r.priority;
      f.provinceNumber = r.provinceNumber,
      f.artSummary = r.artSummary;
      f.accessoryName = r.accessoryName;
      f.filePath = r.filePath;
      f.externalUrl = r.externalUrl;
      f.synchronizedStatus = r.synchronizedStatus == 1 ? true : false;
    },
    // 添加法律
    addLaga() {
      this.formValidate.lagaList.push({
        lagalPublishTime: "",
        lagalStatus: "",
        lagalMessage: "",
      });
    },
    subLaga(index) {
      this.formValidate.lagaList.splice(index,1)
    },
    handleAuther1(list) {
      this.formValidate.articleAuthorList = list;
    },
    changeTime(obj) {
      this.formValidate.applyTime = obj;
    },
    changeTime1(obj) {
      this.formValidate.publishTime = obj;
    },
    handleSuccess(res, file) {
      const MaxSize = 1024 * 1024 * 20;
      if (file.size > MaxSize) {
        this.$Message.error("文件太大，请重新上传");
      }
      this.formValidate.filePath = res.content.url;
      console.log(res);
      this.formValidate.accessoryName = file.name;
    },
    // 文件删除处理
    handleFileDel(e) {
      if (
        e.target.className ===
        "ivu-icon ivu-icon-ios-close ivu-upload-list-remove"
      ) {
        this.formValidate.filePath = ""; // 文件路径
        this.formValidate.accessoryName = ""; // 文件名称
      }
    },
    clearField() {
      // 清空
      this.formValidate.title = "";
      this.formValidate.patentType = "";
      this.formValidate.patentNumber = "";
      this.formValidate.applyTime = "";
      this.formValidate.publicNumber = "";
      this.formValidate.publishTime = "";
      this.formValidate.applyUser = "";
      this.formValidate.patentAddress = "";
      this.formValidate.classifyNumber = "";
      this.formValidate.masterClassifyNumber = "";
      this.formValidate.pageNumber = "";
      this.formValidate.proxyMechanism = "";
      this.formValidate.proxyUser = "";
      this.formValidate.principalRight = "";
      this.formValidate.priority = "";
      this.formValidate.provinceNumber = "",
      this.formValidate.artSummary = "";
      this.formValidate.accessoryName = "";
      this.formValidate.filePath = "";
      this.formValidate.externalUrl = "";
      this.formValidate.synchronizedStatus = false;
    },
    isEmpty(f) {
      if (
        f.title === "" &&
        f.patentType === "" &&
        f.patentNumber === "" &&
        f.applyTime === "" &&
        f.publicNumber === "" &&
        f.publishTime === "" &&
        f.applyUser === "" &&
        f.patentAddress === "" &&
        f.classifyNumber === "" &&
        f.masterClassifyNumber === "" &&
        f.pageNumber === "" &&
        f.proxyMechanism === "" &&
        f.proxyUser === "" &&
        f.principalRight === "" &&
        f.provinceNumber === "" &&
        f.artSummary === "" &&
        f.accessoryName === "" &&
        f.filePath === "" &&
        f.synchronizedStatus === ""
      ) {
        this.$Message.error("不能为空");
        return true;
      }
      if (f.title === "") {
        this.$Message.error("专利名称不能为空");
        return true;
      } else if (!f.provinceNumber || f.provinceNumber === "") {
        this.$Message.error("国别省市代码不能为空");
        return true;
      } else if (f.artSummary === "") {
        this.$Message.error("摘要不能为空");
        return true;
      } else if (f.accessoryName === "") {
        this.$Message.error("附件不能为空");
        return true;
      } else if (f.patentType === "") {
        this.$Message.error("专利类型不能为空");
        return true;
      } else if (f.patentNumber === "") {
        this.$Message.error("申请专利号不能为空");
        return true;
      } else if (f.applyTime === "") {
        this.$Message.error("申请日不能为空");
        return true;
      } else if (f.publicNumber === "") {
        this.$Message.error("公告号不能为空");
        return true;
      } else if (f.publishTime === "") {
        this.$Message.error("公告日不能为空");
        return true;
      } else if (f.applyUser === "") {
        this.$Message.error("申请人不能为空");
        return true;
      } else if (f.patentAddress === "") {
        this.$Message.error("地址不能为空");
        return true;
      } else if (f.classifyNumber === "") {
        this.$Message.error("分类号不能为空");
        return true;
      } else if (f.classifyNumber.length > 50) {
        this.$Message.error("分类号过长");
        return true;
      } else if (f.masterClassifyNumber === "") {
        this.$Message.error("分类号不能为空");
        return true;
      } else if (f.masterClassifyNumber.length > 50) {
        console.log(1);
        this.$Message.error("主分类号过长");
        return true;
      } else if (f.pageNumber === "") {
        this.$Message.error("页数不能为空");
        return true;
      } else if (f.proxyMechanism === "") {
        this.$Message.error("代理机构不能为空");
        return true;
      } else if (f.proxyUser === "") {
        this.$Message.error("代理人不能为空");
        return true;
      } else if (f.principalRight === "") {
        this.$Message.error("主权项不能为空");
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-tree ul li {
  margin: 0;
}
::v-deep .ivu-tree-children li {
  display: flex;
}
::v-deep .ivu-tree-children li .ivu-tree-arrow {
  display: none;
}
::v-deep .ivu-tree-children li .ivu-tree-children li {
  margin: 30px 40px 0 -60px;
}
::v-deep .ivu-tree-children li .ivu-tree-title {
  width: 100px;
}
::v-deep .ivu-radio-group {
  white-space: nowrap;
}
.form-content-container {
  margin: 38px auto 0;
  position: relative;
}
.ivu-input-wrapper ::v-deep input {
  border: none;
  background-color: #fff;
  height: 38px;
}
.ivu-input-wrapper ::v-deep input::-webkit-input-placeholder,
.ivu-input-wrapper ::v-deep textarea::-webkit-input-placeholder {
  color: #999;
}
.ivu-upload ::v-deep .ivu-upload-drag {
  border: none;
  display: flex;
  align-items: center;
}
::v-deep .ivu-upload {
  display: flex;
  align-items: center;
}
.ivu-input-wrapper ::v-deep textarea {
  border: none;
}
.form-container {
  width: 1200px;
  min-height: 1228px;
  background-color: #fff;
  padding: 60px 100px 0;
  margin: 4px auto 30px;
  box-shadow: 0px 7px 15px 1px rgba(22, 26, 100, 0.13);
  .form-item {
    width: 100%;
    min-height: 85px;
    padding: 25px 0;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    position: relative;
    &:not(:last-child) {
      border-bottom: 1px solid #dcdcdc;
    }
    .plus {
      width: 16px;
      height: 16px;
      line-height: 14px;
      text-align: center;
      background: #FFFFFF;
      border: 1px dotted #00A4FF;
      border-radius: 3px;
      font-size: 10px;
      color: #00A4FF;
      cursor: pointer;
    }
    .title {
      width: 900px;
      height: 38px;
      line-height: 38px;
      position: relative;
      ::v-deep input {
        font-size: 36px;
      }
      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .xing {
      width: 30px;
      color: red;
      text-align: center;
    }
    .left,
    .right {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .left {
      border-right: 1px solid #dcdcdc;
      width: 355px;
    }
    .right {
      margin-left: 10px;
      width: 642px;
    }
    .year,
    .center,
    .stage {
      display: flex;
      align-items: center;
    }
    .year {
      width: 280px;
      border-right: 1px solid #dcdcdc;
    }
    .center {
      width: 320px;
      border-right: 1px solid #dcdcdc;
    }
    .stage {
      width: 370px;
    }
    .checkbox {
      width: 970px;
      display: flex;
      padding-left: 7px;
      label {
        margin-right: 20px;
      }
    }
    .upload {
      display: flex;
      padding-left: 7px;
      align-items: center;
      label {
        margin-right: 20px;
      }
    }
  }
}
.submit-container {
  height: 70px;
  background-color: #fff;
  text-align: center;
  line-height: 70px;
  .btns {
    margin-left: 800px;
  }
  button {
    width: 90px;
    height: 36px;
    margin: 0 10px;
    border: 1px dashed #333333;
    border-radius: 8px;
    &:last-child {
      background: #00a4ff;
      border: 1px solid #00a4ff;
      border-radius: 8px;
      color: #fff;
    }
  }
}
</style>
